import React from "react";
import useGetImage from "./useGetImage";
import Img from "gatsby-image";
import s from "./Content.module.scss";
const Content = () => {
    const { img, phoneIcon, buildIcon, emailIcon } = useGetImage();
    return (<section className={s.box}>
      <div className={s.textBox}>
        <h2 className={s.mainTitle}>Honored to be Your Hop Grower</h2>
        <p className={s.text}>
          We appreciate your interest and support! One of the leading hop
          growers in America, Virgil Gamache farms is the home of world-famous
          Amarillo® brand hops and other quality hop varieties.
        </p>
        <div className={s.wrap}>
          <div className={s.imgWrap}>
            <Img className={s.img} fluid={img.childImageSharp.fluid}/>
          </div>
          <div className={s.address}>
            <h2 className={s.title}>Virgil Gamache Farms, Inc.</h2>
            <div className={s.addressBox}>
              <img className={s.icon} src={phoneIcon.publicURL} alt="phone"/>
              <span>
                Office:{' '}
                <a href="tel:5098656422" target="_blank" rel="noopener noreferrer">
                  509-865-6422
                </a>
              </span>
            </div>
            <div className={s.addressBox}>
              <img className={s.icon} src={emailIcon.publicURL} alt="phone"/>
              <a href="mailto:info@vgfinc.com" target="_blank" rel="noopener noreferrer">
                <span>info@vgfinc.com</span>
              </a>
            </div>
            <div className={s.addressBox}>
              <img className={s.icon} src={buildIcon.publicURL} alt="address"/>
              <a href="https://maps.app.goo.gl/vF2YXhTdeQ86yfXG6" target="_blank" rel="noopener noreferrer">
              <span>6371 Fort Road, Toppenish, WA, 98948</span>
                </a>
            </div>
          </div>
        </div>
      </div>
    </section>);
};
export default Content;
