import React, { useState } from "react";
import s from "./Form.module.scss";
import { useLocation } from '@reach/router';
const defaultState = {
    name: "",
    email: "",
    phone: "",
    message: "",
};
const Form = () => {
    const [state, setState] = useState(defaultState);
    const handleSetValue = (event) => {
        const { currentTarget: { name, value }, } = event;
        setState(state => ({ ...state, [name]: value }));
    };
    const location = useLocation();
    return (<section className={location.pathname === "/contacts" ? s.box : s.boxVarieties}>
      <h2 className={s.mainTitle}>{location.pathname === "/contacts" ? "Contact us" : "Looking for another variety?"}</h2>

      <form action={"/thank-you"} className={s.form} name="new-contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="bot-field"/>
        <input type="hidden" name="form-name" value="new-contact"/>
        <div className={s.row}>
          <div className={s.col}>
            <input required className={s.input} id="name" name="name" placeholder="Name" type="text" value={state.name} onChange={handleSetValue}/>
          </div>
          <div className={s.col}>
            <input required className={s.input} id="phone" name="phone" placeholder="Phone" type="text" value={state.phone} onChange={handleSetValue}/>
          </div>
        </div>
        <input required className={s.input} id="email" name="email" placeholder="Email" type="text" value={state.email} onChange={handleSetValue}/>
        <textarea className={s.area} name="message" placeholder="Message" id="" value={state.message} onChange={handleSetValue}/>
        <div className={s.btnBox}>
          <button className={s.btn} type="submit">
            MESSAGE
          </button>
        </div>
      </form>
    </section>);
};
export default Form;
