import { useStaticQuery, graphql } from "gatsby";
export default () => {
    const data = useStaticQuery(graphql `
    query {
      img: file(relativePath: { eq: "images/contacts/ContactPage.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      emailIcon: file(relativePath: { eq: "images/contacts/mail.svg" }) {
        publicURL
      }
      phoneIcon: file(relativePath: { eq: "images/contacts/phone.svg" }) {
        publicURL
      }
      buildIcon: file(relativePath: { eq: "images/contacts/build.svg" }) {
        publicURL
      }
    }
  `);
    return data;
};
