import React from "react";
import Contacts from "../scenes/contacts";
import SEO from "../components/seo";
import { keywords } from "../content/keywords";
export default () => {
    return (<>
      <SEO title={"Contact"} keywords={keywords}/>
      <Contacts />
    </>);
};
